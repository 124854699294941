<template>
  <!--<div style="margin: 20px">-->
  <!--  <div style="margin-bottom: 30px">-->
  <!--    <div class="row">-->
  <!--      <label class="col-2">موضوع</label>-->
  <!--      <label>{{reply.startTicket.title}}</label>-->
  <!--    </div>-->
  <!--    <div class="row">-->
  <!--      <label class="col-2"> متن پیام</label>-->
  <!--      <label>{{reply.startTicket.message}}</label>-->
  <!--    </div>-->
  <!--    <div class="row">-->
  <!--      <div class="col-4">-->
  <!--        <label>تاریخ ارسال</label>-->
  <!--        <label>{{getPersianDate(reply.startTicket.createdAtUtc)}}</label>-->
  <!--      </div>-->

  <!--      <div class="col-4">-->
  <!--        <label>دپارتمان </label>-->
  <!--        <label>{{reply.department.title}}</label>-->
  <!--      </div>-->

  <!--      <div class="col-4">-->
  <!--        <label> اولویت </label>-->
  <!--        <label>{{reply.startTicket.priority === 'Normal' ? 'متوسط' : (reply.startTicket.priority === 'Low') ? 'پایین' : 'بالا'}}</label>-->
  <!--      </div>-->

  <!--    </div>-->
  <!--  </div>-->
  <!--  <hr style="margin-bottom: 40px">-->
  <!--  <div class="row">-->
  <!--    <div style="width: 100%">-->
  <!--      <div class="row" v-for="(item,index) in replies" :key="index" >-->
  <!--        <div  class="col-6">-->
  <!--          <label v-if="item.writtenByUser" class="commentDiv" style="color: #0d060e;background-color: #eff7fa">{{item.message}}</label>-->
  <!--          <label v-if="item.writtenByUser == false" style="color: black;margin-top: 23px;">{{item.writtenByUser ? 'کاربر : ': 'ادمین : '}} {{item.writer}} {{getPersianDate(item.createdAtUtc)}} </label>-->
  <!--        </div>-->
  <!--        <div  class="col-6">-->
  <!--          <label v-if="item.writtenByUser == false" class="commentDiv" style="color: #0d060e;background-color: #eff7fa">{{item.message}}</label>-->
  <!--          <label v-if="item.writtenByUser" style="color: black;margin-top: 23px;margin-right: -235px;">{{item.writtenByUser ? 'کاربر : ': 'ادمین : '}} {{item.writer}} {{getPersianDate(item.createdAtUtc)}} </label>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--  <hr style="margin-bottom: 40px;margin-top: 40px">-->
  <!--  <div class="row">-->
  <!--    <div class="col-2">-->
  <!--      <button @click="SendReply()" style="border-radius: 8px !important;width: 90%;height: 42px;background-color: #02b5a0 !important;height: 35px;margin-top: 29px">ارسال پیام</button>-->
  <!--    </div>-->
  <!--    <div class="col-10">-->
  <!--      <textarea v-model="message"></textarea>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--</div>-->
  <div>
    <v-card>
      <v-responsive :aspect-ratio="16/9">
        <v-row>
          <v-col>
            <v-card-text style="margin-top: 16px;margin-right: 10px" class="panelTitle">پاسخگویی به پیام پشتیبانی</v-card-text>
          </v-col>
        </v-row>
        <hr style="margin: 0 0.5rem">
        <v-row style="margin: 0.5rem 1rem 0 0 ">
          <v-col cols="2">
            <span>متن پیام</span>
          </v-col>
          <v-col cols="5">
            <input class="disableInput" :value="reply.startTicket.message">
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row style="margin: 0 1rem 0 0 ">
          <v-col cols="2">
            <span>تاریخ پیام</span>
          </v-col>
          <v-col cols="5">
            <input class="disableInput" :value="getPersianDate(reply.startTicket.createdAtUtc)">
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row style="margin: 0 1rem 0 0 ">
          <v-col cols="2">
            <span>دپارتمان</span>
          </v-col>
          <v-col cols="5">
            <input class="disableInput" :value="reply.department.title">
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row style="margin: 0 1rem 0 0 ">
          <v-col cols="2">
            <span>اولویت</span>
          </v-col>
          <v-col cols="5">
            <input class="disableInput" :value="reply.startTicket.priority === 'Normal' ? 'متوسط' : (reply.startTicket.priority === 'Low') ? 'پایین' : 'بالا'">
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row style="margin: 0 1rem 0 0">
          <v-col cols="2">
            <span>متن پیام ها</span>
          </v-col>
          <v-col cols="10" style="background-color: #f4f4f7;padding-left: 3rem;border-radius: 8px">
            <div v-for="(item,index) in replies" :key="index">
              <v-col style="font-size: 0.8rem;">{{item.writtenByUser ? 'کاربر : ': 'ادمین : '}} {{item.writer}} {{getPersianDate(item.createdAtUtc)}}</v-col>
              <v-col style="font-size: 0.8rem;">{{item.message}}</v-col>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin: 0 1rem 0 0">
          <v-col cols="2"></v-col>
          <v-col cols="5">
            <input class="Input" v-model="message">
          </v-col>
          <v-col>
            <v-btn color="success" @click="SendReply()">پاسخ به کاربر</v-btn>
            <v-btn color="error" @click="ticketClose(reply.startTicket.id)">بستن تیکت</v-btn>
          </v-col>
        </v-row>
        <v-row style="margin: 0 1rem 0 0">
          <v-col cols="1"></v-col>
          <v-col>
            <v-btn @click="message = 'سلام چطور میتونم کمکتون کنم'" style="background-color: #f4f4f7 !important;">سلام چطور میتونم کمکتون کنم</v-btn>
          </v-col>
          <v-col>
            <v-btn @click="message = 'لطفا صبر کنید'" style="background-color: #f4f4f7 !important;">لطفا صبر کنید</v-btn>
          </v-col>
          <v-col>
            <v-btn @click="message = 'مشکل بر طرف شد'" style="background-color: #f4f4f7 !important;">مشکل بر طرف شد</v-btn>
          </v-col>
        </v-row>
      </v-responsive>
    </v-card>
  </div>
</template>

<script>
import axiosApi from "@/axios";
import getPersianDateTime from "@/plugins/getPersianDate";
import Vue from "vue";
export default {
  name: "ReplyTicket",
  data() {
    return {
      'reply': '',
      'replies': '',
      'message': '',
      id: ''
    };
  },
  mounted() {
    this.GetDetails();
  },
  methods: {
    GetDetails() {
      axiosApi().post('/api/Manage/v1/Ticket/GetDetails', {
        'startTicketId': this.$root.replyTicket
      }).then(({ data }) => {
        this.reply = data.data;
        this.id = data.data.startTicket.id;
        this.replies = data.data.replies;
      });
    },
    getPersianDate(date) {
      return getPersianDateTime(date);
    },
    SendReply() {
      axiosApi().post('/api/Manage/v1/Ticket/SendReply', {
        startTicketId: this.$root.replyTicket,
        message: this.message
      }).then(() => {
        this.GetDetails();
        this.message = '';
      });
    },
    ticketClose() {
      axiosApi().post('/api/Manage/v1/Ticket/CloseTicket', {
        id: this.id
      }).then(({ data }) => {
        if (data['isSuccess']) {
          Vue.$toast.open({
            message: 'تیکت با موفقیت بسته شد',
            type: 'success',
            position: 'top-right'
          });
        } else
          data.Errors.forEach((item) => {
            Vue.$toast.open({
              message: item.Description,
              type: 'error',
              position: 'top-right'
            });
          });
      });
    }
  }
};
</script>

<style scoped>
.commentDiv {
  width: 100%;
  padding: 12px;
  border-radius: 10px 0px 10px 10px;
  margin-top: 10px !important;
}
textarea {
  width: 97.3%;
  border: 1px solid;
  padding: 10px 12px;
  position: relative;
  min-height: 100px !important;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
}
</style>
